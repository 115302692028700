export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';
export const RESET_PROGRESS = 'RESET_PROGRESS';

export const ADD_USER_INFO = 'ADD_USER_INFO';
export const REMOVE_USER_INFO = 'REMOVE_USER_INFO';

export const ADD_PROJECT_INFO = 'ADD_PROJECT_INFO';
export const REMOVE_PROJECT_INFO = 'REMOVE_PROJECT_INFO';

export const GET_IMAGE_BACKGROUND = 'GET_IMAGE_BACKGROUND';

export const PATH_IMAGE = `${process.env.REACT_APP_BACKEND_URL}/api/v1/survey/display-path?path=`;