import React from 'react';
import './style.scss';
import Spin from 'antd/es/spin';
import { useSelector } from 'react-redux';

const ProgressTurn = ({ show }) => {
  const loading = useSelector(state => state.progressReducer.loading);

  if (!loading && !show) {
    return null;
  }

  return (
    <div className="progress-turn">
      <Spin size="large" />
    </div>
  )
}

export default React.memo(ProgressTurn);
