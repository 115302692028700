import React, { lazy, Suspense } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import Header from "components/page/mainpage/Header";
import { ProgressTurn } from "components/common";
import { useSelector } from "react-redux";
import FooterBanner from "components/page/mainpage/FooterBanner";
// import { PATH_IMAGE } from "../redux/constants";

const Servey = lazy(() => import("pages/servey/Servey"));
const Version = lazy(() => import("pages/util/Version"));
const NotFoundPage = lazy(() => import("pages/util/NotFoundPage"));

const LIST_PAGE = [
  // { path: HOME_PAGE, component: Home },
  { path: "/survey/:id", component: Servey },
  { path: "/survey/response/:id&:cid&:start&:end&:key", component: Servey },
  { path: "/version", component: Version },
  { path: "*", component: NotFoundPage },
];
const MainPage = () => {
  const imgLink = useSelector((state) => state.formReducer.footerImage);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <div className="bg-purple-50 min-h-full">
        <Suspense fallback={<ProgressTurn show />}>
          <Switch>
            {LIST_PAGE.map((item, index) => (
              <Route
                key={index}
                exact={item.exact ?? true}
                path={item.path}
                component={item.component}
              />
            ))}
          </Switch>
        </Suspense>
      </div>
      <FooterBanner />
    </Layout>
  );
};

export default React.memo(MainPage);
