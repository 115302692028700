import FooterImg from "assets/images/vnpt_footer_banner.jpg";
import "./header.scss";
import { useSelector } from "react-redux";
import { PATH_IMAGE } from "redux/constants";

function FooterBanner() {
  const imgLink = useSelector((state) => state.formReducer.footerImage);
  return (
    <footer className="flex flex-col">
      <img
        src={imgLink ? `${PATH_IMAGE}${imgLink}` : FooterImg}
        alt="Footer Banner"
        className="footer-banner"
        style={{
          width: "100%",
          height: "auto",
        }}
      />
      <div className="footer-license text-white h-8 flex items-center justify-center border-t-2 border-blue-400">
        © Bản Quyền thuộc Tập đoàn Bưu chính Viễn thông Việt Nam
      </div>
    </footer>
  );
}

export default FooterBanner;
