import React from "react";
import { Route, Switch } from "react-router-dom";
import { FORGOT_PASSWORD, HOME_PAGE } from "global/routes";
import ForgotPassword from "./util/ForgotPassword";
import MainPage from "./MainPage";
import { Provider, useSelector } from "react-redux";
import { ConfigProvider, Typography } from "antd";

function AppWrapper() {
  // const authorized = useSelector(state => state.userReducer.authorized);
  const fontFamily = useSelector((state) => state.formReducer.fontFamily);
  const textColor = useSelector((state) => state.formReducer.textColor);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: fontFamily,
          colorTextBase: textColor,
        },
      }}
    >
      <Switch>
        <Route path={FORGOT_PASSWORD} component={ForgotPassword} exact />
        <Route path={HOME_PAGE}>
          <MainPage />
        </Route>

        {/* <Route path={LOGIN} exact>
        {authorized ? <Redirect to={HOME_PAGE} /> : <Login />}
      </Route>

      <Route path={HOME_PAGE}>
        {!authorized ? <Redirect to={LOGIN} /> : <MainPage />}
      </Route> */}
      </Switch>
    </ConfigProvider>
  );
}

export default AppWrapper;
