import React, { useState } from "react";
import "./style.scss";
import { Form, Input } from "antd";
import { CommonService } from "api/CommonService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/fontawesome-free-solid";

const InputForm = ({
  placeholder,
  customInput,
  customClass,
  name,
  isRequired,
  helpText,
  isPhoneNumber,
  onlyNumber,
  defaultValue,
  textRequire,
  isEmail,
  isPassword,
  onChange,
}) => {
  let rules = [];
  const [visible, setVisible] = useState(true);
  if (isRequired) {
    rules = [
      {
        required: true,
        message: textRequire || "Trường bắt buộc không được để trống!",
      },
      ...rules,
    ];
  }
  if (isPhoneNumber) {
    rules = [
      {
        pattern: CommonService.REGEX_PHONE_NUMBER,
        message: "Số điện thoại không hợp lệ!",
      },
      ...rules,
    ];
  }

  if (isEmail) {
    rules = [
      {
        pattern: CommonService.REGEX_EMAIL,
        message: "Email không hợp lệ!",
      },
      ...rules,
    ];
  }

  return (
    <div className={`input-common ${customClass || ""}`}>
      <Form.Item name={name} noStyle rules={rules}>
        <div className="input-container">
          <Input
            placeholder="&nbsp;"
            autoComplete="true"
            defaultValue={defaultValue}
            className={`${customInput || ""} custom-input`}
            type={isPassword && visible ? "password" : isPhoneNumber || onlyNumber ? "number" : "text"}
            onChange={(e) => onChange(e.target.value)}
          />
          <span className="input-common__placeholder">{placeholder}</span>
          <span class="input-highlight"></span>
          
        </div>
      </Form.Item>
      
      <p className="input-common__help-text pl-4 pt-1 mb-0">{helpText}</p>
      {isPassword && (
        <div className="absolute inset-y-0 right-4 pr-3 flex items-center text-sm leading-5">
          <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} className="text-2xl" onClick={() => setVisible(!visible)} />
        </div>
      )}
    </div>
  );
};

InputForm.defaultProps = {
  onChange: () => {},
};

export default React.memo(InputForm);
