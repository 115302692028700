export const HOME_PAGE = '/';
export const PROFILE_DETAIL = '/profile-detail';
export const ROLES = '/roles';
export const USERS = '/users';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const INFO = '/info';
export const SEGMENTS = '/segments'
export const FIND_PEOPLE = '/find-people'
export const RFM = '/rfm'
export const FORGOT_PASSWORD = '/forgot-password';
export const SEGMENTS_CREATE_CARD = '/segment-create-card';
export const SEGMENT_CREATE_PAST = '/segment-create-past';
export const SEGMENT_CREATE_USER = '/segment-create-user';
export const SEGMENT_CREATE_LIST_BASE = '/segment-create-list-base';
export const MANAGE_PROJECT = '/manage-project';
export const CREATE_PROJECT = '/create-project';
export const DASHBOARDS = '/dashboards';

//param segment create action
export const CREATE_ACTION = '/action';
export const CREATE_INACTION = '/inaction';
export const CREATE_ACTION_USER = '/action-user'
export const CREATE_SINGLE_ACTION = '/single-action'
export const CREATE_ON_DATE_TIME = '/on-date-time'
export const CREATE_PAGE_VISIT = '/page-visit';
export const CREATE_REFFERRER_ENTRY = '/refferrer-entry';
export const CREATE_PAGE_COUNT = '/page-count';
export const CREATE_INACTION_TIME = '/inaction-time';
export const LINK_PROFILE_DETAIL = "/profile-view"
