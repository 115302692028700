import { GET_IMAGE_BACKGROUND } from "../constants";

const initialState = {
  headerImage: "",
  footerImage: "",
  fontFamily: "inter",
  textColor: "#FFFFF"
};

export function formSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IMAGE_BACKGROUND:
      return {
        ...state,
        headerImage: action.payload.headerImage,
        footerImage: action.payload.footerImage,
        fontFamily: action.payload.fontFamily,
        textColor: action.payload.textColor
      };

    default:
      return state;
  }
}
