import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { progressReducer } from "./progressReducer";
import { projectReducer } from "./projectReducer";
import { formSurveyReducer } from "./formSurveyReducer";

export const rootReducer = combineReducers({
  formReducer: formSurveyReducer,
  userReducer,
  progressReducer,
  projectReducer,
});
